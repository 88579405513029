<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Finanzas</h4>
            <div class="small text-muted">Administración de todo el proceso financiero</div>
          </b-col>
        </b-row>
      </b-card>

      <b-row>
        <b-col md="9">
          <b-row>
            <b-col md="4">                       
              <Widget :configWidget="configWidget" reference="list_cash_amount_now" :hasAccess="configWidget.elements.listCashAmountNow" />                                          
            </b-col>
            <b-col md="8">                       
              <Widget :configWidget="configWidget" reference="list_valores_expirations" :hasAccess="configWidget.elements.listValoresExpirations" />                                          
            </b-col>                        
          </b-row>
        </b-col>  
        <b-col md="3">
          <b-list-group>
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openCashDetail()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Movimientos de Cajas</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Ver movimientos de cajas
              </p>
            </b-list-group-item>
          </b-list-group>
          <b-list-group>
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openCheques()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Cartera de Valores</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Ver los valores en cartera
              </p>
            </b-list-group-item>
          </b-list-group>
          <b-list-group>
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openSettings()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Configuraciones</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Configuración del módulo
              </p>
            </b-list-group-item>
          </b-list-group>
        </b-col>        
      </b-row>

    </b-col>
  </b-row>
</template>
<script>
  //import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'
  import Widget from '@/components/inc/widget/widget'

  export default {
    components: {
      Widget
    },
    data: () => {
      return {
        access: {
          module_id: Modules.FINANZAS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'dashboard',
          elements: {}
        },
        configWidget: {
          module_id: Modules.FINANZAS,
          profile_id: Profiles.PERSONAL,
          elements: {             
            listCashAmountNow: true,            
            listValoresExpirations: true,
          }
        },
        primaryColor: '',
      }
    },
    created () {
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */

      /* Configurar permisos de los widgets */      
      this.configWidget.elements.listCashAmountNow = Helper.hasAccessWidget(this.configWidget, 'list_cash_amount_now')      
      this.configWidget.elements.listValoresExpirations = Helper.hasAccessWidget(this.configWidget, 'list_valores_expirations')      
      /* Fin configuracion */
    },
    mounted() {
      this.setup()
    }, 
    methods: {
      setup () {        
        if(Session.getSession().settings) {    
          this.primaryColor = Session.getSession().settings.color_primary
                    
          this.createCSS()
        }
      },         
      createCSS() {
        var hoja = document.createElement('style')
        hoja.innerHTML = ""      
        hoja.innerHTML = hoja.innerHTML  + ".list-group-item-action:hover, .list-group-item-action:focus { background-color: " + this.primaryColor + "; }"

        document.body.appendChild(hoja);
      }, 
      openCashDetail() {
        this.$router.push({ name: 'FinancesStaffCashDetail' })
      }, 
      openCheques() {
        this.$router.push({ name: 'FinancesStaffCheques' })
      }, 
      openSettings() {
        this.$router.push({ name: 'FinancesStaffSettings' })
      },          
    }    
   
  }
</script>

<style>

</style>
